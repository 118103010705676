import React, { useState, useEffect } from "react";
import { Table, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const { Column } = Table;

const DynamicTable = ({ data, columns, onRowClick, enableOnRowClick }) => {
    const [filterTable, setFilterTable] = useState([]);

    useEffect(() => {
        // Ensure data is an array
        if (Array.isArray(data)) {
            setFilterTable(data);
        }
    }, [data]);

    const onSearch = (value) => {
        if (!Array.isArray(data)) return;
        const filteredTable = data.filter((record) => {
            return Object.keys(record).some((key) =>
                String(record[key]).toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilterTable(filteredTable);
    };

    const onChange = (pagination, filters, sorter) => {
        if (!Array.isArray(data)) return;
        let sortedTable = [...data];
        if (sorter.order === "ascend") {
            sortedTable.sort((a, b) =>
                a[sorter.field] > b[sorter.field] ? 1 : -1
            );
        } else if (sorter.order === "descend") {
            sortedTable.sort((a, b) =>
                a[sorter.field] < b[sorter.field] ? 1 : -1
            );
        }
        setFilterTable(sortedTable);
    };
    const onRow = enableOnRowClick
        ? (record) => ({ onClick: () => onRowClick(record) })
        : undefined;

    return (
        <>
            <div style={{ alignItems: "flex-end" }}>
                <div
                    style={{
                        marginBottom: 16,
                        marginRight: 16,
                        position: "absolute",
                        right: 0,
                        top: "-3.5rem",
                    }}>
                    <Input
                        placeholder="Search Table"
                        prefix={<SearchOutlined />}
                        allowClear
                        onChange={(e) => onSearch(e.target.value)}
                        style={{ width: 200, marginRight: 8 }}
                    />
                </div>
                <Table
                    rowClassName={(record) => {
                        return record.is_expiring_soon ? "redBackground" : "";
                    }}
                    columns={columns}
                    dataSource={filterTable.length > 0 ? filterTable : data}
                    pagination={{ pageSize: 10 }}
                    bordered
                    size="middle"
                    scroll={{ x: "max-content", y: "380px" }}
                    style={{ borderRadius: "2%" }}
                    onRow={onRow}
                    onChange={onChange}>
                    {columns.map((column) => (
                        <Column key={column.dataIndex} {...column} />
                    ))}
                </Table>
            </div>
        </>
    );
};

export default DynamicTable;
