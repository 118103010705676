import React, { useEffect, useState } from "react";
import AddPolicy from "./AddPolicy";

import { getPolicyData } from "../../Adapters/PolicyDashboard";
import _ from "lodash";

import TablePolicy from "./TablePolicy";
import { userState } from "../../RecoilState/userState";
import { useRecoilState } from "recoil";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import CustomButton from "../../components/Utils/CustomButton";

const PolicyDashboard = () => {
    const [showAddEditUser, setShowAddEditUser] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const canChangePolicy = user?.group_permissions?.includes("change_policy");

    useEffect(() => {
        !isEditMode && setSelectedRecord([]);
    }, [isEditMode]);

    const fetchPolicyData = async (policyId) => {
        try {
            const res = await getPolicyData(policyId || selectedRecord.id);
            setSelectedRecord(res.data);
        } catch (error) {
            console.error("Failed to fetch policy data:", error);
        }
    };
    const handleRowClick = (record) => {
        if (canChangePolicy) {
            setSelectedRecord(record);
            setShowAddEditUser(true);
            setIsEditMode(true);
        }
        fetchPolicyData(record.id);
    };
    const hasPermission = user?.group_permissions?.includes("add_policy");

    const dashboardProps = {
        className: showAddEditUser ? "" : "dashboardBody",
        heading: "Polices",
        ...(hasPermission &&
            !showAddEditUser && {
                buttons: (
                    <CustomButton
                        image=""
                        text="Add Policy"
                        onClick={() => setShowAddEditUser(true)}
                    />
                ),
            }),
        body: !showAddEditUser ? (
            <TablePolicy
                onRowClick={(record) => {
                    handleRowClick(record);
                }}
                selectedRecord={selectedRecord}
                setSelectedRecord={setSelectedRecord}
            />
        ) : (
            <AddPolicy
                setShowAddEditUser={setShowAddEditUser}
                isEditMode={isEditMode} // Pass isEditMode as a prop
                setIsEditMode={setIsEditMode}
                recordData={selectedRecord} // Pass selectedRecord as a prop when in "edit" mode
                refreshFile={fetchPolicyData}
            />
        ),
    };
    return (
        <>
            <DashboardLayout {...dashboardProps} />
        </>
    );
};

export default PolicyDashboard;
